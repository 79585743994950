import Image from "next/image";

import CardSlider from "components/organisms/CardSlider";
import TestimonialCard from "components/organisms/TestimonialsGallery/TestimonialCard";

import { BackgroundColors } from "types/theme.types";

export interface Testimonial {
  image: string;
  left: {
    author: string;
    content: string;
    bg: BackgroundColors;
  };
  right: {
    author: string;
    content: string;
    bg: BackgroundColors;
  };
  alt: string;
}

interface Props {
  testimonials: Testimonial[];
}

function TestimonialsGallery(props: Props) {
  const testimonials = Array.isArray(props.testimonials)
    ? props.testimonials
    : [];

  return (
    <div className="-m-1">
      <CardSlider numberOfPills={testimonials.length}>
        <CardSlider.Pills />
        <CardSlider.Body>
          {testimonials.map((testimonial, index) => (
            <CardSlider.Slide key={index}>
              <div className="grid grid-cols-1 gap-4 md:gap-8 px-1">
                <div className="max-w-[550px] mx-auto w-4/5 md:w-1/2">
                  <Image
                    alt={testimonial.alt}
                    src={testimonial.image}
                    width={560}
                    height={396}
                  />
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-11 md:px-10">
                  <TestimonialCard testimonial={testimonial.left} />
                  <TestimonialCard testimonial={testimonial.right} />
                </div>
              </div>
            </CardSlider.Slide>
          ))}
        </CardSlider.Body>
      </CardSlider>
    </div>
  );
}

export default TestimonialsGallery;
