import Image from "next/image";

import Typography from "components/atoms/Typography";

import classnames from "lib/classnames";

interface Props {
  content: {
    alt: string;
    imageUrl: string;
    label: string;
    title: string;
  };
  containerClassName?: string;
}

export default function PhotoCard(props: Props) {
  const { content } = props;

  const textContainerClassName = classnames.gradientContainer(
    props.containerClassName
  );

  return (
    <div className="rounded-2xl overflow-hidden relative w-full aspect-square md:h-92">
      <Image
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        src={content.imageUrl}
        alt={content.alt}
      />

      <div className={textContainerClassName}>
        <Typography.H6 className="text-white text-opacity-70">
          {content.title}
        </Typography.H6>
        <Typography.H3 className="text-white mt-3">
          {content.label}
        </Typography.H3>
      </div>
    </div>
  );
}
