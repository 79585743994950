import { ComponentProps } from "react";

import ContentCardsWithTitle from "components/templates/ContentCardsWithTitle";

export const CONTENT_CARDS_WITH_TITLE: ComponentProps<
  typeof ContentCardsWithTitle
> = {
  title: "We help you grow",
  color: "text-green-gray",
  cards: [
    {
      bg: "bg-green-black",
      color: "text-green-bright",
      icon: "fak fa-sv-rocket",
      title: "Hire on demand",
      paragraph:
        "We have a large community of early-career sales professionals—with hundreds of new graduates every month—that are ready to be matched with your company.",
      link: {
        text: "Learn more",
        url: "/employers",
        color: "text-yellow-pale",
      },
    },
    {
      bg: "bg-sv-burgundy",
      color: "text-sv-red",
      icon: "fak fa-sv-door",
      title: "Build your business career",
      paragraph:
        "Get everything you need to successfully launch a new career, including hands-on training, a powerful community, career transition support, and professional coaching.",
      link: {
        text: "Learn more",
        url: "/program",
        color: "text-yellow-pale",
      },
    },
  ],
};
