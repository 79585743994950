import Link from "next/link";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";

export type FooterLink = {
  text: string;
  href: string;
};

interface Props {
  links: FooterLink[];
}

export default function PreFooter(props: Props) {
  return (
    <Section className="bg-warm-light py-12 md:py-24">
      <div className="px-4">
        <Typography.H4 className="text-green-gray">
          We can help you
        </Typography.H4>
        <ul className="pt-4 md:pt-6">
          {props.links.map((item, index) => (
            <li key={`footer-link-${index}`} className="pt-6">
              <Link href={item.href}>
                <a className="hover:no-underline">
                  <Typography.H2 className="text-green-gray hover:text-peach-bright">
                    {item.text} <i className="fa-light fa-arrow-right-long" />
                  </Typography.H2>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Section>
  );
}
