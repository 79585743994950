import clsx from "clsx";
import Image, { ImageProps } from "next/image";
import { ReactNode } from "react";

import ReadMoreDown from "components/atoms/ReadMoreDown";
import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import CTAButton from "components/molecules/CTAButton";

import classnames from "lib/classnames";

import { CTA } from "types/common.types";
import { TextColors } from "types/theme.types";

interface HeroWithImageProps {
  image: Partial<ImageProps>;
  title?: string;
  color?: TextColors;
  subtitle?: string;
  children?: ReactNode;
  mobileImage?: Partial<ImageProps>;
  callToAction?: CTA;
  showLearnMoreIcon?: boolean;
}

function HeroWithImage(props: HeroWithImageProps) {
  const hasCallToAction = props.callToAction?.text && props.callToAction.link;

  const imageContainerClassName = clsx(
    "overflow-hidden",
    "relative",
    "md:h-full",
    "w-full",
    "md:absolute",
    "md:top-0",
    "md:right-0",
    "md:w-1/2",
    "md:pl-4"
  );

  const titleClassName = clsx(
    "text-center",
    "mb-8",
    "md:text-left",
    props.color
  );

  const buttonClassName = clsx(
    classnames.button("primary"),
    "rounded-lg",
    "w-max",
    "px-16"
  );

  const heroWithImageClassName = clsx(
    "bg-warm-light",
    "relative",
    "md:pt-0",
    "md:h-[90vh]",
    "md:max-h-[750px]",
    "md:min-h-[850px]",
    hasCallToAction ? "lg:min-h-[750px]" : "lg:min-h-[600px]"
  );

  const subtitleClassName = clsx(
    "text-medium-gray",
    hasCallToAction ? "text-center md:text-left" : ""
  );

  return (
    <div className={heroWithImageClassName}>
      <Section className="pt-22 pb-8 md:pt-30 overflow-hidden">
        <div className="md:w-1/2 md:pr-6">
          <Typography.H1 className={titleClassName}>
            {props.children || props.title}
          </Typography.H1>
          <Typography.BodyLg className={subtitleClassName}>
            {props.subtitle}
          </Typography.BodyLg>
          {hasCallToAction && (
            <div className="mt-6 px-16 md:px-0 flex justify-center md:block">
              <CTAButton
                className={buttonClassName}
                callToAction={props.callToAction}
              />
            </div>
          )}
        </div>
      </Section>
      {props.image?.src && (
        <div className={imageContainerClassName}>
          <div className="hidden md:block">
            <Image
              alt={props.image.alt}
              layout="fill"
              src={props.image.src}
              objectFit="cover"
              objectPosition="left top"
            />
          </div>
          <div className="md:hidden">
            <Image
              alt={props.mobileImage?.alt}
              layout="responsive"
              width={1125}
              height={1125}
              src={props.mobileImage?.src || props.image.src}
            />
          </div>
        </div>
      )}
      {props.showLearnMoreIcon && (
        <div className="absolute hidden -bottom-[3.75rem] w-full justify-center z-1 md:flex">
          <ReadMoreDown />
        </div>
      )}
    </div>
  );
}

export default HeroWithImage;
