import { InfoItem } from "components/organisms/TabbedInfo";

export const TABS: InfoItem[] = [
  {
    head: {
      icon: "fak fa-sv-fast",
      title: "5x",
      label: "On average, our employer partners find great talent 5x faster.",
    },
    body: {
      photoCard: {
        title: "team goals",
        label: "Top recruiters trust us to provide the best talent",
        imageUrl: "/images/home/tabs/lives-and-teams-jennifer-hance.webp",
        alt: "sv academy grads",
      },
      quote: {
        color: "text-white",
        bg: "bg-green-black",
        author: "Jennifer Hance Leon",
        quote:
          '"If I see SV Academy on a resume, I immediately move that candidate forward in our hiring process."',
        label: ", Global Senior Sales Recruiter",
        logoUrl: "/images/home/tabs/logo-bigcommerce.svg",
        alt: "BigCommerce logo",
      },
    },
  },
  {
    head: {
      icon: "fak fa-sv-money",
      title: "76%",
      label: "More than 76% of our grads belong to an underrepresented group.",
    },
    body: {
      photoCard: {
        title: "career goals",
        label: "A 20-year military veteran becomes Account Manager",
        imageUrl: "/images/home/tabs/lives-and-teams-will-wilson.webp",
        alt: "sv academy grads",
      },
      quote: {
        color: "text-white",
        bg: "bg-sv-burgundy",
        author: "Will Wilson",
        quote:
          '"I was greeted with so much love and support that it was a bit overwhelming."',
        label: ", Cloud Account Manager • Tech Sales Program, 2021",
        logoUrl: "/images/home/tabs/logo-jfrog.svg",
        alt: "JFrog logo",
      },
    },
  },
  {
    head: {
      icon: "fak fa-sv-fist",
      title: "3000+",
      label: "Over 3000 learners have graduated from SV Academy.",
    },
    body: {
      photoCard: {
        title: "career goals",
        label: "From skeptic to Tech Sales Development Representative",
        imageUrl: "/images/home/tabs/lives-and-teams-madison-chandler.webp",
        alt: "sv academy grads",
      },
      quote: {
        color: "text-green-black",
        bg: "bg-orange-bright",
        author: "Madison Chandler",
        quote:
          '"I was 70% sure it was a scam. Just graduated today with multiple interviews."',
        label: ", SDR • Tech Sales Program, 2021",
        logoUrl: "/images/home/tabs/logo-catalyst-software.svg",
        alt: "Catalyst Software logo",
      },
    },
  },
  {
    head: {
      icon: "fak fa-sv-stairs",
      title: "$0",
      label: "No fees for recruitment, onboarding, and training.",
    },
    body: {
      photoCard: {
        title: "life goals",
        label: "First promotion in 6 months, first-time homeowner in 9",
        imageUrl: "/images/home/tabs/lives-and-teams-gabrielle-vanhoesen.webp",
        alt: "sv academy grads",
      },
      quote: {
        color: "text-white",
        bg: "bg-green-gray",
        author: "Gabrielle Vanhoesen",
        quote: '"Just closed on my house and y\'all are the reason why."',
        label: ", District Success Manager • Customer Success Program, 2021",
        logoUrl: "/images/home/tabs/logo-codecombat.svg",
        alt: "CodeCombat logo",
      },
    },
  },
];
