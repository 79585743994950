import clsx from "clsx";
import Image, { ImageProps } from "next/image";

import Typography from "components/atoms/Typography";

import { BackgroundColors, TextColors } from "types/theme.types";

export interface FullWidthCardProps {
  bg: BackgroundColors;
  color: TextColors;
  image: Partial<ImageProps>;
  paragraph: string;
  title: string;
}

export default function FullWidthCard(props: FullWidthCardProps) {
  const cardClassName = clsx(
    "mx-auto",
    "p-6",
    "rounded-3xl",
    "space-y-6",
    "w-full",
    "md:p-11",
    props.bg,
    props.color
  );

  const contentClassName = clsx(
    "grow",
    "shrink-0",
    "text-base",
    "md:basis-1/2",
    props.color
  );

  return (
    <div className={cardClassName}>
      <Typography.H4 className={props.color}>{props.title}</Typography.H4>
      <div className="gap-6 grid grid-cols-1 items-start md:grid-cols-2 md:grid-rows-1">
        <Typography.BodyLg className={contentClassName}>
          {props.paragraph}
        </Typography.BodyLg>
        <div className="rounded-2xl overflow-hidden grow shrink-0 md:basis-1/2">
          <Image
            src={props.image.src}
            alt={props.image.alt}
            width={440}
            height={212}
            layout="responsive"
          />
        </div>
      </div>
    </div>
  );
}
