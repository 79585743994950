import gsap from "gsap";
import Draggable from "gsap/dist/Draggable";
import clamp from "lodash/clamp";
import range from "lodash/range";
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export enum SlideDirection {
  Left,
  Right,
}
type SliderContextType = {
  slideTo: (direction: SlideDirection) => void;
  moveXPositionTo: (xPosition: number) => void;
  currentX: number;
  stripRef: MutableRefObject<HTMLDivElement | null>;
  slideWidth: number;
  itemsTotal: number;
};

type Props = {
  children: ReactNode;
  numberOfPills: number;
};

const SliderContext = createContext({} as SliderContextType);

export const useSliderContext = () => useContext(SliderContext);

export const SliderContextProvider = ({ children, numberOfPills }: Props) => {
  // This number is a measure in pixels once the slide drag is outside of this boundary, the swipe animation triggers
  // lowest value = more sensitive swipe
  // highest value = less sensitive swipe
  const SWIPE_BOUNDARY = 50;
  const [stripXPosition, setStripXPosition] = useState(0);
  const stripRef = useRef(null);

  const shouldSwipe = (newPosition: number) => {
    const { currentX } = stripRef.current;
    return Math.abs(currentX - newPosition) > SWIPE_BOUNDARY;
  };

  const computeSwipeDirection = (newPosition: number) => {
    const { currentX } = stripRef.current;
    return newPosition > currentX ? SlideDirection.Left : SlideDirection.Right;
  };

  const slideTo = (direction: SlideDirection) => {
    const { currentX } = stripRef.current;
    const slideWidth = stripRef?.current?.children[0].offsetWidth ?? 0;
    const start = -1 * (numberOfPills - 1) * slideWidth;
    let newPosition: number;

    switch (direction) {
      case SlideDirection.Left:
        newPosition = currentX + slideWidth;
        break;
      case SlideDirection.Right:
        newPosition = currentX - slideWidth;
        break;
    }

    newPosition = clamp(newPosition, start, 0);
    moveXPositionTo(newPosition);
  };

  // When drag, check if should swipe based on the boundary
  // if should swipe, move the swipe direction
  // else snap to the closest slide axis
  // then, update the x position
  const snapToClosest = (range: number[], position: number) => {
    if (shouldSwipe(position)) {
      const direction = computeSwipeDirection(position);
      slideTo(direction);
    } else {
      const difference = calculateStripToSlideDifference();
      const snapPosition = gsap.utils.snap(range, position - difference);
      moveXPositionTo(snapPosition);
    }
  };

  const moveXPositionTo = (xPosition: number) => {
    const difference = calculateStripToSlideDifference();
    // TODO:
    //
    // @Wences
    //
    // GSAP is huge in the website bundle, so we need to refactor this, ideally we should
    // use animate like in the example below.
    // Replace: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    gsap.to(stripRef.current, {
      x: xPosition + difference,
    });
    // With: ==================================================================
    // const transformMatrix = window
    //   .getComputedStyle(stripRef.current)
    //   .getPropertyValue("transform");

    // const translate = [
    //   { transform: transformMatrix },
    //   { transform: `matrix(1,0,0,1,${xPosition + difference}, 0)` },
    // ];
    // const timing = {
    //   duration: 300,
    //   fill: "forwards",
    // };
    // stripRef.current.animate(translate, timing);
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>
    stripRef.current.currentX = xPosition;
    setStripXPosition(xPosition);
  };

  const calculateStripToSlideDifference = () => {
    const stripWidth = stripRef?.current?.offsetWidth ?? 0;
    const slideWidth = stripRef?.current?.children[0]?.offsetWidth ?? 0;
    return (stripWidth - slideWidth) / 2;
  };

  const initializeDraggable = () => {
    const slideWidth = stripRef?.current?.children[0]?.offsetWidth ?? 0;
    const start = -1 * (numberOfPills - 1) * slideWidth;
    const slidesRange = range(start, 1, slideWidth);

    const [draggable] = Draggable.create(stripRef.current, {
      type: "x",
      onDragEnd: () => snapToClosest(slidesRange, draggable.x),
    });

    moveXPositionTo(0);
  };

  //Initialize drag/swipe
  useEffect(() => {
    gsap.registerPlugin(Draggable);
    initializeDraggable();

    // We need to reset the measurements
    // in case the window resizes
    window.addEventListener("resize", initializeDraggable);

    return () => window.removeEventListener("resize", initializeDraggable);
  }, []);

  return (
    <SliderContext.Provider
      value={{
        slideTo,
        moveXPositionTo,
        currentX: stripXPosition,
        stripRef,
        itemsTotal: numberOfPills,
        slideWidth: stripRef?.current?.children[0]?.offsetWidth ?? 0,
      }}
    >
      {children}
    </SliderContext.Provider>
  );
};
