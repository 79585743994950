import clsx from "clsx";
import Link from "next/link";
import { ReactNode } from "react";

import Typography from "components/atoms/Typography";

import { BackgroundColors, TextColors } from "types/theme.types";

interface IconProps {
  icon: string;
  color?: string;
  className?: string;
}
function Icon(props: IconProps) {
  return (
    <i
      className={clsx(
        "text-5xl md:text-6xl fak",
        props.icon,
        props.color,
        props.className
      )}
    />
  );
}

interface TitleProps {
  color: TextColors;
  children: ReactNode;
  className?: string;
}
function Title(props: TitleProps) {
  return (
    <Typography.H4 className={props.className} color={props.color}>
      {props.children}
    </Typography.H4>
  );
}

interface ParagraphProps {
  color: TextColors;
  children: ReactNode;
  className?: string;
}
function Paragraph(props: ParagraphProps) {
  return (
    <Typography.BodyLg color={props.color} className={props.className}>
      {props.children}
    </Typography.BodyLg>
  );
}

interface CardLinkProps {
  href: string;
  children: ReactNode;
  color?: TextColors;
  openNewTab?: boolean;
}
function CardLink(props: CardLinkProps) {
  return (
    <Link href={props.href}>
      <a
        className="mt-auto"
        target={props.openNewTab ? "_blank" : "_self"}
        rel="noreferrer noopener"
      >
        <Typography.BodyLg className={clsx("items-center flex", props.color)}>
          {props.children} <i className="fa fa-arrow-right ml-2" />
        </Typography.BodyLg>
      </a>
    </Link>
  );
}
export interface ContentCardProps {
  color?: TextColors;
  title?: string;
  paragraph?: string;
  bg?: BackgroundColors;
  contentColor?: TextColors;
  contentClassName?: string;
  icon?: string;
  link?: {
    text: string;
    url?: string;
    color?: TextColors;
  };
  children?: ReactNode;
  className?: string;
}
function ContentCard(props: ContentCardProps) {
  const cardClassName = clsx(
    "flex",
    "flex-1",
    "flex-col",
    "items-start",
    "p-6",
    "md:p-11",
    "rounded-2xl",
    props.bg,
    props.className
  );

  const iconClassName = clsx("mb-2", "md:mb-5");

  const contentClassName = clsx(
    "mt-4",
    "md:mt-8",
    props.link && "mb-4",
    props.link && "md:mb-8",
    props.contentClassName
  );

  return (
    <div className={cardClassName}>
      {props.icon && (
        <Icon className={iconClassName} icon={props.icon} color={props.color} />
      )}
      {props.title && <Title color={props.color}>{props.title}</Title>}
      {props.paragraph && (
        <Paragraph
          color={props.contentColor || "text-white"}
          className={contentClassName}
        >
          {props.paragraph}
        </Paragraph>
      )}
      {props.link && (
        <CardLink href={props.link.url} color={props.link.color}>
          {props.link.text}
        </CardLink>
      )}
      {props.children}
    </div>
  );
}

ContentCard.Icon = Icon;
ContentCard.Title = Title;
ContentCard.Paragraph = Paragraph;
ContentCard.Link = CardLink;

export default ContentCard;
