import clsx from "clsx";

import Typography from "components/atoms/Typography";

export interface BulletCardProps {
  bg: string;
  color: string;
  icon?: string;
  paragraph: string;
}

export default function BulletCard(props: BulletCardProps) {
  const cardClassName = clsx(
    "flex",
    "flex-row",
    "md:flex-col",
    "items-center",
    "md:items-start",
    "gap-4",
    "p-6",
    "md:p-8",
    "rounded-2xl",
    props.bg
  );

  const iconClassName = clsx(
    "text-5xl",
    "md:text-6xl",
    props.color,
    props.icon
  );

  return (
    <div className={cardClassName}>
      {props.icon && <i className={iconClassName} />}
      <Typography.Body className={props.color}>
        {props.paragraph}
      </Typography.Body>
    </div>
  );
}
