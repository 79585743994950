import { ScrollerRowProps } from "components/organisms/LogoScroller/ScrollerRow";

import { formatImage } from "lib/utils";

const LEFT_LOGOS = [
  {
    alt: "hootsuite",
    width: 123,
    src: "/images/logos/top-row/logo-hootsuite.svg",
  },
  {
    alt: "miro",
    width: 98,
    src: "/images/logos/top-row/logo-miro.svg",
  },
  {
    alt: "hubspot",
    width: 101,
    src: "/images/logos/top-row/logo-hubspot.svg",
  },
  {
    alt: "amazon",
    width: 128,
    src: "/images/logos/top-row/logo-amazon.svg",
  },
  {
    alt: "apple",
    width: 41,
    src: "/images/logos/top-row/logo-apple.svg",
  },
  {
    alt: "salesforce",
    width: 64,
    src: "/images/logos/top-row/logo-salesforce.svg",
  },
  {
    alt: "surveymonkey",
    width: 190,
    src: "/images/logos/top-row/logo-survey-monkey.svg",
  },
  {
    alt: "reddit",
    width: 118,
    src: "/images/logos/top-row/logo-reddit.svg",
  },
  {
    alt: "at&t",
    width: 99,
    src: "/images/logos/top-row/logo-att.svg",
  },
  {
    alt: "paypal",
    width: 117,
    src: "/images/logos/top-row/logo-paypal.svg",
  },
  {
    alt: "alation",
    width: 123,
    src: "/images/logos/top-row/logo-alation.svg",
  },
];

const RIGHT_LOGOS = [
  {
    alt: "seismic",
    width: 108,
    src: "/images/logos/bottom-row/logo-seismic.svg",
  },
  {
    alt: "udemy",
    width: 89,
    src: "/images/logos/bottom-row/logo-udemy.svg",
  },
  {
    alt: "meta",
    width: 99,
    src: "/images/logos/bottom-row/logo-meta.svg",
  },
  {
    alt: "snowflake",
    width: 131,
    src: "/images/logos/bottom-row/logo-snowflake.svg",
  },
  {
    alt: "google",
    width: 100,
    src: "/images/logos/bottom-row/logo-google.svg",
  },
  {
    alt: "6sense",
    width: 90,
    src: "/images/logos/bottom-row/logo-6sense.svg",
  },
  {
    alt: "slack",
    width: 100,
    src: "/images/logos/bottom-row/logo-slack.svg",
  },
  {
    alt: "uber",
    width: 64,
    src: "/images/logos/bottom-row/logo-uber.svg",
  },
  {
    alt: "paloalto",
    width: 131,
    src: "/images/logos/bottom-row/logo-paloalto.svg",
  },
  {
    alt: "box",
    width: 54,
    src: "/images/logos/bottom-row/logo-box.svg",
  },
  {
    alt: "handshake",
    width: 145,
    src: "/images/logos/bottom-row/logo-handshake.svg",
  },
];

export const LOGO_ROWS: ScrollerRowProps[] = [
  {
    animationDirection: "ToLeft",
    logos: LEFT_LOGOS.map(formatImage),
  },
  {
    animationDirection: "ToRight",
    logos: RIGHT_LOGOS.map(formatImage),
  },
];
