import { Transition } from "@headlessui/react";
import { ReactNode, useState } from "react";

import { BackgroundColors, TextColors } from "types/theme.types";

import PhotoCard from "./PhotoCard";
import Quote from "./Quote";
import Tab from "./Tab";

export interface InfoItem {
  head: {
    icon?: string;
    title: string;
    label: string;
  };
  body: {
    photoCard: {
      alt: string;
      imageUrl: string;
      label: string;
      title: string;
    };
    quote: {
      alt: string;
      author: string;
      bg: BackgroundColors;
      color: TextColors;
      label: string;
      logoUrl: string;
      quote: string;
    };
  };
}

interface TabbedInfoProps {
  tabs: InfoItem[];
}

export default function TabbedInfo(props: TabbedInfoProps) {
  const { tabs = [] } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const tabList: ReactNode[] = tabs.map(({ head }, index) => {
    const isActive = index === activeIndex;
    const onClick = () => setActiveIndex(index);
    return (
      <Tab key={index} isActive={isActive} onClick={onClick} content={head} />
    );
  });

  return (
    <div>
      <div className="grid grid-cols-4 gap-6 lg:gap-9">{tabList}</div>
      {tabs.map(({ body }, index) => (
        <Transition
          key={index}
          show={index === activeIndex}
          enter="transition-all duration-300"
          enterFrom="translate-y-4 opacity-0"
          enterTo="translate-y-0 opacity-1"
          leave="opacity-0"
          className="flex mt-9 items-end h-full"
        >
          <div className="w-2/3">
            <PhotoCard content={body.photoCard} />
          </div>
          <div className="w-1/3 ml-9">
            <Quote content={body.quote} />
          </div>
        </Transition>
      ))}
    </div>
  );
}
