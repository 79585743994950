import HeroWithImage from "components/templates/HeroWithImage";

export const HERO_WITH_IMAGE_PROPS: Readonly<
  React.ComponentProps<typeof HeroWithImage>
> = {
  color: "text-sv-red",
  title: `NO Fees.
          Don't pay for entry level talent.`,
  subtitle:
    "Hire and onboard highly trained and qualified sales and customer success professionals in a fraction of the time, backed by an award-winning training program.",
  image: {
    src: "/images/home/hero-bg.webp",
    alt: "Hero SV Community Desktop",
    priority: true,
  },
  mobileImage: {
    src: "/images/home/hero-bg-mobile.webp",
    alt: "Hero SV Community Mobile",
    priority: true,
  },
};
