export const BULLET_CARDS = [
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-overlap",
    paragraph: "We screen applicants to match your desired candidate profile",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-lightning",
    paragraph: "We intensively train residents in as little as 2 weeks",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-briefcase",
    paragraph:
      "Residents receive ongoing training and mentorship during their 90 day residency",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-compass",
    paragraph:
      "We aim to be an extension of your management team to ensure residents are ramping quickly and hitting metrics",
  },
];
