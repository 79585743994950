import { FooterLink } from "components/organisms/PreFooter";

export const LINKS: FooterLink[] = [
  {
    text: "Grow your team",
    href: "/employers",
  },
  {
    text: "Launch a career",
    href: "/program",
  },
];
