import { FullWidthCardProps } from "components/molecules/FullWidthCard";

export const FULL_WIDTH_CARDS: FullWidthCardProps[] = [
  {
    bg: "bg-green-gray",
    color: "text-warm-light",
    title: "Education",
    paragraph:
      "We are leading the evolution of traditional education to create dynamic programs that accelerate growth for our grads and partners. As markets continue to evolve, sales roles remain crucial for thriving businesses and our graduates are equipped to seize opportunities.",
    image: {
      src: "/images/home/partners/education-pulse-event.webp",
      alt: "SV Academy at Education Pulse Event",
    },
  },
  {
    bg: "bg-green-pale",
    color: "text-green-black",
    title: "Opportunity",
    paragraph:
      "We are disrupting the Tech Industry hand-in-hand with the world’s top SaaS VC behind Salesloft, Docusign, Udemy, and OwnBackup. They create opportunities and our grads help drive the change within their portfolio companies.",
    image: {
      src: "/images/home/partners/opportunity-insight.webp",
      alt: "Insight Partners Logo",
    },
  },
  {
    bg: "bg-warm-light",
    color: "text-green-black",
    title: "Equality",
    paragraph:
      "We joined Coursera and the Milken Institute, along with some of the biggest names in tech including Meta, Google, IBM, and Intuit, to enable free on-demand educational resources to over 200,000 Americans. So far, the Salesforce and SV Academy Sales Development Representative Professional Certificate has already served more than 15,000 learners.",
    image: {
      src: "/images/home/partners/equality-coursera.webp",
      alt: "Office person with Coursera and Salesforce Logos ",
    },
  },
];
