import ScrollerRow, {
  ScrollerRowProps,
} from "components/organisms/LogoScroller/ScrollerRow";

interface Props {
  logos: ScrollerRowProps[];
}

export default function LogoScroller(props: Props): JSX.Element {
  return (
    <section className="flex flex-col justify-between overflow-hidden">
      {props.logos.map((row, index) => (
        <div
          key={`logo-scroller-row-container-${index}`}
          className="flex relative mt-6 md:mt-8 first:mt-0 first:md:mt-0"
        >
          <ScrollerRow
            animationDirection={row.animationDirection}
            logos={row.logos}
          />
        </div>
      ))}
    </section>
  );
}
