import clsx from "clsx";

import Typography from "components/atoms/Typography";

import { BackgroundColors } from "types/theme.types";

interface Props {
  testimonial: {
    bg: BackgroundColors;
    content: string;
    author: string;
  };
}

export default function TestimonialCard(props: Props) {
  const { testimonial } = props;
  return (
    <div
      className={clsx(
        "p-6",
        "rounded-3xl",
        "text-white",
        "md:p-8",
        testimonial.bg
      )}
    >
      <Typography.H5 className="mb-4">{testimonial.content}</Typography.H5>
      <Typography.BodySm className="opacity-75">
        {testimonial.author}
      </Typography.BodySm>
    </div>
  );
}
