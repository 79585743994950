import { ReactNode } from "react";

import AttachedCards from "./AttachedCards";
import Body from "./Body";
import Pills from "./Pills";
import Slide from "./Slide";
import { SliderContextProvider } from "./context/SliderContext";

interface Props {
  children: ReactNode;
  numberOfPills?: number;
}

function CardSlider(props: Props) {
  return (
    <SliderContextProvider numberOfPills={props.numberOfPills}>
      {props.children}
    </SliderContextProvider>
  );
}

CardSlider.Slide = Slide;
CardSlider.Body = Body;
CardSlider.Pills = Pills;
CardSlider.AttachedCards = AttachedCards;

export default CardSlider;
