import clsx from "clsx";
import { Children, cloneElement, ReactNode } from "react";

import { SlideSize } from "components/organisms/CardSlider/Slide";
import {
  SlideDirection,
  useSliderContext,
} from "components/organisms/CardSlider/context/SliderContext";

interface Props {
  slideSize?: SlideSize;
  children: ReactNode;
  className?: string;
}

export default function Body(props: Props) {
  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];
  const { stripRef, slideTo } = useSliderContext();

  const containerClassName = clsx(
    "overflow-hidden",
    "w-full",
    "relative",
    props.className
  );

  return (
    <div className={containerClassName}>
      <div ref={stripRef} className={props.className || "flex"}>
        {Children.map(children, (child) =>
          cloneElement(child, { size: props.slideSize })
        )}
      </div>
      <div
        onClick={() => slideTo(SlideDirection.Left)}
        className="h-full w-1/12 absolute top-0 left-0"
      />
      <div
        onClick={() => slideTo(SlideDirection.Right)}
        className="h-full w-1/12 absolute top-0 right-0"
      />
    </div>
  );
}
