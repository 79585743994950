import clsx from "clsx";

import Typography from "components/atoms/Typography";

interface Props {
  content: {
    label: string;
    title: string;
  };
  isActive: boolean;
  onClick: () => void;
}

export default function Tab(props: Props) {
  const { isActive, content, onClick } = props;

  const colors = isActive
    ? ({
        line: "border-sv-red",
        title: "text-sv-red",
        label: "text-medium-gray",
      } as const)
    : ({
        line: "border-light-gray",
        title: "text-light-gray",
        label: "text-light-gray",
      } as const);

  const lineClassName = clsx("border-t-4", "mb-4", "rounded", colors.line);

  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div className={lineClassName} />
      <Typography.H2 color={colors.title}>{content.title}</Typography.H2>
      <Typography.BodySm color={colors.label} className="mt-4">
        {content.label}
      </Typography.BodySm>
    </div>
  );
}
