import clsx from "clsx";
import range from "lodash/range";

import { useSliderContext } from "components/organisms/CardSlider/context/SliderContext";

import { BackgroundColors } from "types/theme.types";

interface Props {
  backgroundColor?: BackgroundColors;
  className?: string;
}

function Pills(props: Props) {
  const { itemsTotal, slideWidth, currentX, moveXPositionTo } =
    useSliderContext();

  const className = clsx(
    "flex",
    "justify-center",
    "h-10",
    "gap-3",
    props.className
  );

  return (
    <div className={className}>
      {range(itemsTotal).map((index) => {
        const xPosition = -index * slideWidth;
        return (
          <button
            // eslint-disable-next-line react/no-unknown-property
            key={index}
            type="button"
            className="w-10 h-full p-0"
            onClick={() => moveXPositionTo(xPosition)}
          >
            <span
              className={clsx(
                props.backgroundColor || "bg-medium-gray",
                "block",
                "h-1",
                "rounded-3xl",
                "w-full",
                " transition-opacity",
                currentX === xPosition ? " bg-opacity-100" : "bg-opacity-30"
              )}
            />
          </button>
        );
      })}
    </div>
  );
}

export default Pills;
