import clsx from "clsx";
import { ReactNode } from "react";

export type SlideSize = "1/2" | "2/3" | "3/4" | "4/5" | "5/6" | "11/12";

interface Props {
  sizeBasis?: SlideSize;
  children?: ReactNode;
}

function Slide(props: Props) {
  return (
    <div
      className={clsx(
        "grow",
        "shrink-0",
        "basis-full",
        props.sizeBasis && `md:basis-${props.sizeBasis}`
      )}
    >
      {props.children}
    </div>
  );
}

export default Slide;
