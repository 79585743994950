import clsx from "clsx";
import { ComponentProps } from "react";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import ContentCard from "components/molecules/ContentCard";

import { BackgroundColors, TextColors } from "types/theme.types";

interface Props {
  title: string;
  paragraph?: string;
  textContainerClassName?: string;
  color?: TextColors;
  bg?: BackgroundColors;
  cards: ComponentProps<typeof ContentCard>[];
}
export default function ContentCardsWithTitle(props: Props) {
  return (
    <Section className={clsx("pt-16 pb-4 md:py-24", props.bg)}>
      <div className={clsx("mx-auto", props.textContainerClassName)}>
        <Typography.H2
          className={clsx("text-center mb-6 md:mb-12", props.color)}
        >
          {props.title}
        </Typography.H2>
        {props.paragraph && (
          <Typography.BodyLg
            className={clsx("text-center mb-6 md:mb-12", props.color)}
          >
            {props.paragraph}
          </Typography.BodyLg>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-10">
        {props.cards.map((card) => (
          <ContentCard key={card.title} {...card} />
        ))}
      </div>
    </Section>
  );
}
