import clsx from "clsx";
import Image, { ImageProps } from "next/image";

type AnimationDirection = "ToLeft" | "ToRight";

export interface ScrollerRowProps {
  animationDirection: AnimationDirection;
  logos: { image: Partial<ImageProps> }[];
}

export default function ScrollerRow(props: ScrollerRowProps): JSX.Element {
  const rowRepetitionNumber = 3;
  const rowRepetitions = Array.from({ length: rowRepetitionNumber }).fill(null);

  const logos = props.logos?.map(({ image }) => (
    <div className="mx-4 md:mx-12 my-0" key={`logo-${image.alt}`}>
      <Image
        priority={true}
        layout="fixed"
        width={image.width}
        height={48}
        alt={image.alt}
        src={image.src as string}
      />
    </div>
  ));

  const rowClassNames = clsx(
    "flex relative justify-around",
    {
      "animate-slideshow-to-left": props.animationDirection === "ToLeft",
    },
    {
      "animate-slideshow-to-right": props.animationDirection === "ToRight",
    }
  );

  return (
    <>
      {rowRepetitions.map((_, index) => (
        <div key={`logo-scroller-row-${index}`} className={rowClassNames}>
          {logos}
        </div>
      ))}
    </>
  );
}
