import gsap from "gsap";
import { useEffect } from "react";

function ReadMoreDown() {
  // TODO:
  //
  // @Wences
  //
  // improve arrow animations,
  // could we use just one instead of 4?
  //
  useEffect(() => {
    gsap.fromTo(
      "#arrow1",
      { y: 0, strokeOpacity: 0.08 },
      { y: 5, strokeOpacity: 0, repeat: -1, duration: 0.7 }
    );
    gsap.fromTo(
      "#arrow2",
      { y: 0, strokeOpacity: 0.15 },
      { y: 15, strokeOpacity: 0.08, repeat: -1, duration: 0.7 }
    );
    gsap.fromTo(
      "#arrow3",
      { y: 0, strokeOpacity: 0.25 },
      { y: 15, strokeOpacity: 0.15, repeat: -1, duration: 0.7 }
    );
    gsap.fromTo(
      "#arrow4",
      { y: -5, strokeOpacity: 0 },
      { y: 0, strokeOpacity: 0.25, repeat: -1, duration: 0.7 }
    );
    return () => {
      gsap.killTweensOf("#arrow1");
      gsap.killTweensOf("#arrow2");
      gsap.killTweensOf("#arrow3");
      gsap.killTweensOf("#arrow4");
    };
  }, []);
  return (
    <svg
      width="97"
      height="89"
      viewBox="0 0 97 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="arrow1"
        d="M36 77L48 88L60 77"
        stroke="black"
        strokeOpacity="0.08"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="arrow2"
        d="M36 62L48 73L60 62"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="arrow3"
        d="M36 47L48 58L60 47"
        stroke="black"
        strokeOpacity="0.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="arrow4"
        d="M36 47L48 58L60 47"
        stroke="black"
        strokeOpacity="0.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.12 4.36V15H7.328V13.272H3.056V4.36H1.12ZM9.01063 4.36V15H15.8106V13.272H10.9466V10.456H15.3626V8.728H10.9466V6.088H15.7626V4.36H9.01063ZM25.1366 15H27.1206L23.3286 4.36H20.5926L16.8006 15H18.7846L19.5206 12.856H24.3846L25.1366 15ZM20.1126 11.128L21.9366 5.8L23.7926 11.128H20.1126ZM28.4013 15H30.3373V10.712H31.3293C32.5933 10.712 34.3213 10.904 34.3693 15H36.3212C36.3533 12.424 35.7453 10.744 34.1293 10.072C35.3293 9.704 36.0493 8.696 36.0493 7.48C36.0493 5.656 35.0413 4.36 32.5933 4.36H28.4013V15ZM30.3373 9.112V6.088H32.4653C33.4893 6.088 34.0973 6.584 34.0973 7.528C34.0973 8.296 33.7613 9.112 32.4653 9.112H30.3373ZM38.1356 15H40.0396V5.944L44.4876 15H47.3996V4.36H45.4956V13.016L41.2556 4.36H38.1356V15ZM52.8388 15H54.7428V6.312L57.3508 12.648H59.7348L62.3428 6.312V15H64.2468V4.36H61.1267L58.5828 10.792L55.9588 4.36H52.8388V15ZM71.5354 4.136C68.3354 4.136 66.1274 6.728 66.1274 9.736C66.1274 12.744 68.2234 15.224 71.5354 15.224C74.7194 15.224 76.9274 12.744 76.9274 9.736C76.9274 6.728 74.7834 4.136 71.5354 4.136ZM71.5354 5.944C73.8234 5.944 74.9114 7.944 74.9114 9.768C74.9114 11.608 73.7754 13.432 71.5354 13.432C69.3114 13.432 68.1434 11.608 68.1434 9.768C68.1434 7.944 69.2634 5.944 71.5354 5.944ZM78.8231 15H80.7591V10.712H81.7511C83.0151 10.712 84.7431 10.904 84.7911 15H86.7431C86.7751 12.424 86.1671 10.744 84.5511 10.072C85.7511 9.704 86.4711 8.696 86.4711 7.48C86.4711 5.656 85.4631 4.36 83.0151 4.36H78.8231V15ZM80.7591 9.112V6.088H82.8871C83.9111 6.088 84.5191 6.584 84.5191 7.528C84.5191 8.296 84.1831 9.112 82.8871 9.112H80.7591ZM88.5575 4.36V15H95.3575V13.272H90.4935V10.456H94.9095V8.728H90.4935V6.088H95.3095V4.36H88.5575Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
}

export default ReadMoreDown;
