import { Testimonial } from "components/organisms/TestimonialsGallery";

export const TESTIMONIALS: Testimonial[] = [
  {
    image: "/images/home/testimonials/alation_1.webp",
    alt: "Cropped Picture of Madalyn Aerne",
    right: {
      content:
        '"Thanks SV for providing me the building blocks for a job and a successful career.”',
      author:
        "Madalyn Aerne, Sales Development Manager • Tech Sales Program, 2021",
      bg: "bg-sv-burgundy",
    },
    left: {
      content:
        '"We are lucky to be working with Madalyn and all SV grads we have here at Alation"',
      author: "Tracy Eiler, Chief Marketing Officer",
      bg: "bg-green-black",
    },
  },
  {
    image: "/images/home/testimonials/6sense.webp",
    alt: "Cropped Picture of Aaron Torres",
    right: {
      content:
        '"Ernest, thank you for an amazing recruitment process and for taking a chance on me"',
      author:
        "Aaron Torres, Enterprise Business Development Representative • Tech Sales Program, 2021",
      bg: "bg-sv-burgundy",
    },
    left: {
      content:
        '"When I work with SV grads I have a lot more confidence they\'ll be ready for the job."',
      author: "Ernest Owusu, Sr. Director, Sales Development",
      bg: "bg-green-black",
    },
  },
];
