import { Transition } from "@headlessui/react";
import { ReactNode, Children } from "react";

import { useSliderContext } from "components/organisms/CardSlider/context/SliderContext";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function AttachedCards(props: Props) {
  const { slideWidth, currentX } = useSliderContext();

  return (
    <>
      {Children.map(props.children, (child, index) => {
        const xPosition = -index * slideWidth;
        return (
          <Transition
            show={currentX === xPosition}
            enter="transition-all duration-300 delay-100"
            enterFrom="max-sm:-translate-y-36 opacity-0 absolute md:relative"
            enterTo="translate-y-0 opacity-100"
            leave="transition-all duration-100"
            leaveFrom="opacity-10 translate-y-0"
            leaveTo="opacity-0 max-sm:-translate-y-18 hidden sm:block"
            className={props.className}
          >
            {child}
          </Transition>
        );
      })}
    </>
  );
}
